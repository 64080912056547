import { useMemo } from "react";

import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons/faCircleXmark";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import Link from "next/link";

import { useUserContext } from "utils/context/UserDetailsContext";
import { useStripeSubscription } from "utils/hooks/stripe/useStripeSubscription";
import { useLocalStorage } from "utils/hooks/useLocalStorage";

export const TrialEndWarning = () => {
  const { userDetails } = useUserContext();

  const {
    subscription: { data: customerSubscription },
  } = useStripeSubscription(userDetails?.stripeCustomerId);

  const customerTrialEndDate = useMemo(
    () => customerSubscription?.trialEnd * 1000,
    [customerSubscription]
  );

  const currentDate = useMemo(() => new Date().getTime(), []);

  const trialDaysRemaining = useMemo(
    () =>
      Math.floor((customerTrialEndDate - currentDate) / (24 * 60 * 60 * 1000)),
    [customerTrialEndDate, currentDate]
  );

  const [trialMessageCloseDate, setTrialMessageCloseDate] =
    useLocalStorage<string>("trialMessageCloseDate");

  const date = useMemo(() => new Date().toLocaleDateString("en-GB"), []);

  const showTrialMessage = useMemo(() => {
    return trialMessageCloseDate ? date > trialMessageCloseDate : true;
  }, [trialMessageCloseDate, date]);

  const handleClose = () => {
    const currentDate = new Date().toLocaleDateString("en-GB");
    setTrialMessageCloseDate(currentDate);
  };

  return (
    <>
      {customerSubscription?.status === "trialing" &&
      showTrialMessage &&
      trialDaysRemaining <= 15 ? (
        <Stack
          component="nav"
          className="w-full flex-row items-center justify-center py-2"
          bgcolor="#fffe7a"
          position="relative"
        >
          <Stack
            className="flex-auto"
            pl={2}
            alignItems={{ xs: "center", md: "flex-end" }}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} color="#0F52BA" />
          </Stack>
          <Typography
            variant="tag"
            color="#0F52BA"
            fontWeight={400}
            ml={1}
            sx={{
              textAlign: { xs: "center" },
              maxWidth: { xs: "300px", sm: "100%" },
            }}
          >
            {`Your free trial is about to end in ${trialDaysRemaining} days. To continue your
            subscription `}
            <Link href="/home/account/my-account">click here</Link>.
          </Typography>

          <Stack className="flex-auto" pr={2} alignItems="flex-end">
            <FontAwesomeIcon
              onClick={handleClose}
              icon={faCircleXmark}
              color="#0F52BA"
            />
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};
