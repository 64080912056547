import { useMemo, useEffect, useRef, type PropsWithChildren } from "react";

import { ThemeProvider } from "@mui/system";

import { useThemeContext } from "theme/context/base";
import { setThemeClasses } from "theme/context/tailwind";
import { siteTheme, baseTheme } from "theme/mui-theme";

interface Props {
  team?: string;
  mode?: "light" | "dark";
}

export const ThemeWrapper = ({
  children,
  team,
  mode,
}: PropsWithChildren<Props>) => {
  const { themes, mode: themeMode } = useThemeContext();
  const ref = useRef<HTMLDivElement>(null);

  const customMode = mode ?? themeMode;

  const theme = useMemo(
    () =>
      themes ? siteTheme(themes, customMode, team) : baseTheme(customMode),
    [themes, team, customMode]
  );

  useEffect(() => {
    setThemeClasses(customMode, themes, team, ref.current);
  }, [customMode, themes, team]);

  return (
    <span ref={ref} className="w-full">
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </span>
  );
};
