import { createContext, useContext, useState, type ReactNode } from "react";

import dynamic from "next/dynamic";

import type { AlertColor } from "@mui/material/Alert";

const SnackBar = dynamic(() => import("./components/SnackBar"), { ssr: false });

interface Props {
  children: ReactNode;
}

interface SnackContextProps {
  showSnackBar: (message: string, variant?: AlertColor) => void;
}

// Creating the snack context
export const SnackContext = createContext<SnackContextProps>({
  showSnackBar: null,
});

export const SnackContextProvider = ({ children }: Props) => {
  const [isSnackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");
  const [snackVariant, setSnackVariant] = useState<AlertColor>("info");

  const showSnackBar = (message: string, variant?: AlertColor) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    variant && setSnackVariant(variant);
  };

  const closeSnackBar = () => {
    setSnackBarOpen(false);
    setSnackBarMessage("");
  };

  return (
    <SnackContext.Provider value={{ showSnackBar }}>
      {children}
      <SnackBar
        open={isSnackBarOpen}
        message={snackBarMessage}
        close={closeSnackBar}
        variant={snackVariant}
      />
    </SnackContext.Provider>
  );
};

// Make useSnackContext Hook to easily use our context throughout the application
export const useSnackContext = () => {
  return useContext(SnackContext);
};
