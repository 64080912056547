import { PropsWithChildren, createContext, useContext, useEffect } from "react";

import { useRouter } from "next/router";
import { signOut } from "next-auth/react";

import { resetAnalytics } from "lib/segment";
import { useSnackContext } from "utils/context/SnackBarContext";
import { useLocalStorage } from "utils/hooks/useLocalStorage";

interface AuthContextType {
  path: string;
  setNewPath: (newPath: string) => void;
}

const authContext: AuthContextType = {
  path: "",
  setNewPath: null,
};

export const AuthContext = createContext<AuthContextType>(authContext);

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [path, setPath] = useLocalStorage<string>("path");
  const router = useRouter();
  const { showSnackBar } = useSnackContext();

  useEffect(() => {
    if (router.query.error) {
      signOut({ redirect: false });
      resetAnalytics();

      showSnackBar(router.query.error as string, "error");

      router.push(
        {
          query: {
            from: router.query.from,
          },
        },
        undefined
      );
    }
  }, [router.query]);

  const setNewPath = (newPath: string) => {
    setPath(newPath);
  };

  return (
    <AuthContext.Provider value={{ path, setNewPath }}>
      {children}
    </AuthContext.Provider>
  );
};

// Make useAuthContext Hook to easily use our context throughout the application
export const useAuthContext = () => {
  return useContext(AuthContext);
};
