import React from "react";

import { NextPage } from "next";
import dynamic from "next/dynamic";

import { ErrorBoundary } from "ui/src/ErrorHandling";

import Page from "@/layouts/AuthPage";

import type { SiteProps } from "utils/types";

const LoginForm = dynamic(() => import("@/components/Auth/LoginForm"), {
  ssr: false,
});

const Login: NextPage<SiteProps> = (props) => {
  return (
    <Page metadata={{ title: "Login" }} {...props}>
      <ErrorBoundary>
        <LoginForm />
      </ErrorBoundary>
    </Page>
  );
};

export default Login;

export async function getStaticProps() {
  const { fetchSiteProps } = await import("@/utils/fetchers");
  const siteProps = await fetchSiteProps();

  return { props: { ...siteProps } };
}
