import { FunctionComponent, useState, useMemo, useEffect } from "react";

import { AppBar, Toolbar, useMediaQuery, Stack, Theme } from "@mui/material";
import { useMeasure } from "@uidotdev/usehooks";
import { useMotionValueEvent, useScroll } from "framer-motion";
import dynamic from "next/dynamic";
import router from "next/router";

import { useTeamTheme } from "theme/context/base";
import { useUserContext } from "utils/context/UserDetailsContext";
import { navbarHeight, appbarHeight } from "utils/signals/nabvar";

import { TrialEndWarning } from "./TrialEndWarning";
import { ThemeWrapper } from "../ThemeWrapper";

const AuthToolBar = dynamic(() => import("./AuthToolBar"), { ssr: false });
const DesktopToolBar = dynamic(() => import("./DesktopToolBar"), {
  ssr: false,
});
const MobileToolBar = dynamic(() => import("./MobileToolBar"), { ssr: false });
const SideBar = dynamic(() => import("./SideBar"), { ssr: false });
const SideBarNavMenu = dynamic(() => import("./SideBarNavMenu"), {
  ssr: false,
});
const SideBarUserMenu = dynamic(() => import("./SideBarUserMenu"), {
  ssr: false,
});
const NavBarLogo = dynamic(() => import("./Logo"), { ssr: false });
const NavbarCTA = dynamic(() => import("./NavbarCta"), { ssr: false });

interface Props {
  team?: string;
  isAuthPage?: boolean;
  disableThemeToggle?: boolean;
}

export const NavBar: FunctionComponent<Props> = ({
  team,
  isAuthPage,
  disableThemeToggle,
}) => {
  const [isSideNav, setIsSideNav] = useState(false);
  const [currentSideBar, setCurrentSideBar] = useState<"user" | "nav">("nav");

  const [appbarRef, { height: appbarCalculatedHeight }] = useMeasure();
  const [navbarRef, { height: navbarCalculatedHeight }] = useMeasure();

  const { userDetails, userStatus } = useUserContext();
  const tplTheme = useTeamTheme(team ?? "tpl");

  const navbarBackground = tplTheme?.headerBackground?.hex || "#000000";

  const [navCTAHidden, setNavCTAHidden] = useState(false);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 150) {
      setNavCTAHidden(true);
    } else {
      setNavCTAHidden(false);
    }
  });

  useEffect(() => {
    navbarHeight.value = navbarCalculatedHeight;
  }, [navbarCalculatedHeight]);

  useEffect(() => {
    appbarHeight.value = appbarCalculatedHeight;
  }, [appbarCalculatedHeight]);

  const isNotLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );

  const isUnauthenticatedUser = useMemo(
    () => userStatus === "unauthenticated",
    [userStatus]
  );

  const toggleShowSideBar = () => {
    setIsSideNav((prev) => !prev);
  };

  const showSideBarNavMenu = () => {
    setCurrentSideBar("nav");
    toggleShowSideBar();
  };

  const handleAuth = (page: string) => {
    router.push({
      pathname: `/home${page}`,
      query: {
        from: encodeURIComponent(window.location.href ?? router.asPath),
      },
    });
  };

  return (
    <>
      <TrialEndWarning />
      <AppBar
        ref={appbarRef}
        component="nav"
        position="sticky"
        className="pointer-events-none items-center bg-transparent shadow-none"
      >
        <ThemeWrapper team={team}>
          {/* Added this hacky background box for large screens */}
          <Stack
            bgcolor={navbarBackground}
            className="absolute hidden h-[84px] w-full xl:block"
          />

          <Toolbar
            ref={navbarRef}
            disableGutters
            sx={{
              backgroundColor: navbarBackground,
              justifyContent: isAuthPage ? "center" : "space-between",
              transition: "0.3s",
            }}
            className="pointer-events-auto z-10 mx-auto box-border min-h-16 w-full max-w-desktop items-center px-3.5 py-2.5 md:min-h-[84px] md:py-2.5 lg:px-8"
          >
            <NavBarLogo
              team={team}
              disableBasis={isAuthPage || isNotLargeScreen}
            />
            {isAuthPage ? (
              <AuthToolBar />
            ) : isNotLargeScreen ? (
              <MobileToolBar showSideBarNavMenu={showSideBarNavMenu} />
            ) : (
              <DesktopToolBar
                handleAuth={handleAuth}
                disableThemeToggle={disableThemeToggle}
              />
            )}
          </Toolbar>

          {isNotLargeScreen && !isAuthPage && (
            <SideBar
              isSideNav={isSideNav}
              toggleShowSideBar={toggleShowSideBar}
              topBarColor={navbarBackground}
              team={team}
            >
              {currentSideBar === "nav" ? (
                <SideBarNavMenu
                  user={userDetails}
                  handleLogin={() => handleAuth("/login")}
                  disableThemeToggle={disableThemeToggle}
                />
              ) : (
                <SideBarUserMenu
                  user={userDetails}
                  toggleShowSideBar={toggleShowSideBar}
                  handleLogin={() => handleAuth("/login")}
                />
              )}
            </SideBar>
          )}

          {isUnauthenticatedUser && !isAuthPage && (
            <NavbarCTA
              handleAuth={handleAuth}
              hidden={navCTAHidden}
              backgroundColor={navbarBackground}
            />
          )}
        </ThemeWrapper>
      </AppBar>
    </>
  );
};
