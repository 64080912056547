import React, { FunctionComponent, PropsWithChildren } from "react";

import { Paper } from "@mui/material";
import dynamic from "next/dynamic";

import { MetaTags } from "ui/src/MetaTags";
import { NavBar } from "ui/src/NavBar";

import { AuthContextProvider } from "@/context/AuthPageContext";

import type { SiteProps } from "utils/types";
import type { Team } from "utils/types/teams";

const Footer = dynamic(async () => (await import("ui/src/Footer")).Footer);

interface Props extends SiteProps {
  team?: Team;
}

const Page: FunctionComponent<Props & PropsWithChildren> = ({
  children,
  metadata,
  team,
}) => {
  return (
    <>
      <MetaTags metadata={metadata} />
      <AuthContextProvider>
        <Paper className="flex min-h-screen flex-col">
          <NavBar team={team?.slug} isAuthPage />
          <main>{children}</main>
          <Footer />
        </Paper>
      </AuthContextProvider>
    </>
  );
};

export default Page;
